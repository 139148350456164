import React from 'react'

import Layout from './../components/layout'
import AbuseComponent from '../components/abuse'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout isWhiteHeader paddingTop>
      <SEO
      title='Abuse'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'company_info'}
      flowType={'none'} />
      <AbuseComponent />
    </Layout>
  )
}

export default IndexPage
