import React from 'react'

import { Typography } from '@material-ui/core'
import AbuseContainer from './abuse.styles'

const AbuseComponent = () => {
  return (
    <AbuseContainer>
      <AbuseContainer.backdrop>
        <AbuseContainer.titleBlock>
          <AbuseContainer.title>Report Abuse</AbuseContainer.title>
          <Typography variant="h2">Abuse & Copyright Violation Reporting</Typography>
        </AbuseContainer.titleBlock>
      </AbuseContainer.backdrop>
      <AbuseContainer.content>
        <AbuseContainer.text>
          Sitebuilder.com has systems in place to prevent the platform from being used in a manner
          that violates terms and conditions. We strive to make the web a better place and will not
          allow websites breaching copyright, attempting to take personal details, hosting unsavory
          content, or activity that violates the policies of ICANN or appropriate registry. We
          investigate and record all reports of abuse submitted to us and where appropriate, may
          take action if a violation of any of our terms of service is confirmed.
        </AbuseContainer.text>
        <AbuseContainer.text>
          We may request additional information from you about your report. However, please note we
          may not be able to provide you with specific updates in response to your specific report
          due to privacy reasons.
        </AbuseContainer.text>
        <AbuseContainer.text>
          We aim to have sites deemed to be breaching our terms of service removed within 24 hours
          of reporting.
        </AbuseContainer.text>
        <AbuseContainer.text>
          If you feel you have discovered a violation, please email us at{' '}
          <Typography variant="link2">
            <a href="mailto:abuse@Sitebuilder.com">abuse@Sitebuilder.com</a>
          </Typography>
          .
        </AbuseContainer.text>
      </AbuseContainer.content>
    </AbuseContainer>
  )
}

export default AbuseComponent
