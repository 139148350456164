import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const AbuseContainer = styled.div`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  display: flex;
`

AbuseContainer.backdrop = styled.div`
  background-color: rgb(0, 118, 223);
  background-image: linear-gradient(
    to bottom,
    rgba(33, 150, 243, 0.9) 0%,
    rgba(63, 81, 181, 0.9) 100%
  );
  height: 225px;
  color: #ffffff;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  display: flex;
`

AbuseContainer.titleBlock = styled.div`
  align-self: center;
  text-align: center;
`

AbuseContainer.content = styled.div`
  align-self: center;
  margin-top: 45px;
  width: 75%;
`

AbuseContainer.title = styled(Typography).attrs({
  variant: 'h1',
  gutterBottom: true
})`
  font-size: 50px !important;
`

AbuseContainer.text = styled(Typography).attrs({
  variant: 'body1',
  gutterBottom: true
})`
  font-size: 18px !important;
`

export default AbuseContainer
